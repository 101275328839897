/* Colors */
:root {
    /* Accent Colors */
    --accent-primary: #DE4549;
  
    /* Background Colors */
    --bg-white-5: #FFFFFF0D;
    --bg-white-10: #FFFFFF1A; 
    --bg-white: #FFFFFF;
  
    /* Outline Colors */
    --outline-white-14: #FFFFFF24;
    --outline-white-50: #FFFFFF80;
    --outline-white-100: #FFFFFF;
  
    /* Secondary Colors */
    --success: #4AAFB5;
    --success-5: #4AAFB50D;
  
    /* Text Colors */
    --text-primary: #FFFFFF;
    --text-secondary: #C5C5C5;
    --text-tertiary: #767676;
    --text-placeholder: #8F8F8F;
    --text-inverted: #000000;

    --gradient: linear-gradient(139.01deg, #121212 0%, #3D3D3D 99.09%);
  }

  html{
    --max-width: 1140px;
    --screen-margin: unset;

    box-sizing: border-box;
  }

  @media only screen and (max-width: 1239px) {
    html {
       --max-width: unset;
       --screen-margin: 32px;
    }
  }
 
  @media only screen and (max-width: 600px) {
    html {
       --screen-margin: 16px;
    }

    /* h1{
       font-size: 36px;
       line-height: 48px;
    }

    h2{
       font-size: 32px;
       line-height: 42px;
    } */
 }
  
  /* Typography */
  body {
    font-family: "Avenir Next", sans-serif;
    color: var(--text-primary);
  }
  
  /* Body - Large */
  .body-lg {
    font-weight: 400;
    font-size: 21px;
    line-height: 140%;
    letter-spacing: 0;
  }
  
  /* Body - Medium */
  .body-md-dense,
  .body-md-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0;
  }
  
  .body-md-medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
  }
  
  .body-md-semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
  
  .body-md-bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
  }
  
  /* Body - Small */
  .body-sm-regular {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* Header */
  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: auto;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin: 0;
  }
  
  h3 {
    font-weight: 600;
    font-size: 21px;
    line-height: 140%;
    letter-spacing: 0;
    margin: 0;
  }
  
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.1em;
    margin: 0;
  }

  p{margin: 0;}
  
  .tagline-text {
    font-weight: 500;
    font-size: 16px;
    line-height: auto;
    letter-spacing: 0.12em;
    color: var(--text-tertiary);
    text-transform: uppercase;
  }
  
  /* Input */
  .input-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  
  .input-placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: auto;
  }
  