.advantagesContainer{
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.topContainer h2{
  color: var(--text-primary);
}

.bottomContainer{
  display: flex;
  justify-content: space-between;
  gap: 64px;
}

.leftContainer{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.advantageWrapper{
  border: 1px solid var(--outline-white-14);
  border-radius: 5px;
  padding: 32px;
  color: var(--text-primary);
  transition: background-color 500ms ease, border-color 500ms ease;
}

.advantageWrapper.active{
  background-color: var(--bg-white-5);
  border-color: var(--outline-white-50);
}

.advantageWrapper.active .featureWrapper p{
  color: var(--text-primary);
}

.advantageWrapper,
.topAdvantage,
.bottomAdvantage,
.featureWrapper{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.topAdvantage{
  gap: 10px;
  color: var(--accent-primary);
}

.topAdvantage h1 {
  display: block;
  font-weight: 600;
  font-size: 21px;
  line-height: 140%;
  letter-spacing: 0;
  margin: 0;
}

.featureWrapper{
  gap: 2px;
}

.featureWrapper p{
  color: var(--text-secondary);
}

.imgContainer{
  width: 420px;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 56px;
}

.imgContainer img{
  width: 75%;
  transition: transform 500ms ease, opacity 500ms ease;
}

@media only screen and (max-width: 600px) {
  .advantagesContainer{
    padding: 0 var(--screen-margin);
  }
  .topContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .topContainer p,
  .topContainer h2{
    text-align: center;
  }

  .advantagesContainer{
    gap: 40px;
    margin-bottom: 16px;
  }
  .bottomContainer{
    flex-direction: column;
    gap: 100px;
  }
  .imgContainer{
    width: 100%;
    height: 400px;
  }
}