.container{
  width: 100%;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 6px 28px 0px #00000029;
}

.leftContainer{
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--bg-white-5);
  padding: 40px;
  flex-grow: 1;
  width: 60%;
}

.leftContainer .text{
  line-height: 28.8px;
  text-align: justify;
}

.buttonsContainer{
  display: flex;
  gap: 16px;
}

.imgContainer{
  width: 40%;
}

.container img {
  object-fit: cover; /* Ensures the image scales properly */
  width: 100%; /* Ensures full width within its container */
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .container{
    flex-direction: column-reverse;
    border-radius: unset;
    margin-bottom: 100px;
  }
  .leftContainer{
    width: 100%;
    box-sizing: border-box;
    padding: 24px 16px;
    background: var(--gradient);
  }
  .imgContainer{
    width: 100%;
  }
  .buttonsContainer{
    flex-direction: column;
    width: 100%;
  }
  .buttonsContainer button{
    width: 100%;
  }
}