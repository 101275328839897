.container{
  display: flex;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
}

.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px;
  background-color: var(--bg-white-5);
  border-radius: 5px;
  color: var(--text-secondary);
  flex: 1 1 0px;
  transition: background-color 500ms ease, color 500ms ease;
}

.card p{
  text-align: center;
  font-weight: 500;
}

.card:hover{
  background-color: var(--bg-white-10);
  color: var(--text-primary);
}

.card:hover svg{
  color: var(--accent-primary);
}

.card svg{
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 600px) {
  .container{
    padding: 0 var(--screen-margin);
    flex-direction: column;
    gap: 16px;
    margin-bottom: 100px;
  }
}