.container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--outline-white-14);
  padding: 56px 0;
}

.container p{
  color: var(--text-secondary);
}

.logoContainer{
  display: flex;
  width: 50%;
  gap: 24px;
}
.logoContainer img{
  width: 170px;
  height: 72px;
}

.poweredBy{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  margin-top: -5px;
}

.poweredBy svg{
  height: auto;
  width: 85px;
  margin-left: 2px;
  color: var(--text-secondary);
}

.copyrightContainer{
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2px;
  margin-top: -3px;
  font-size: 14px;
}

.copyrightContainer p{
  text-align: end;
}

@media only screen and (max-width: 600px) {
  .borderContainer{
    padding: 0 16px;
  }
  .container{
    flex-direction: column;
    gap: 32px;
    padding: 56px 0;
    box-sizing: border-box;
  }
  .logoContainer{
    justify-content: center;
  }
  .logoContainer,
  .copyrightContainer{
    width: 100%;
  }
  .copyrightContainer{
    margin: 0;
  }
  .copyrightContainer p{
    text-align: center;
  }
  .poweredBy svg{
    margin-left: 1px;
  }
}