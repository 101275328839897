body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainContainer{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bottomContainer{
  width: 100%;
  background: var(--gradient);
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-left: var(--screen-margin);
  padding-right: var(--screen-margin);
  box-sizing: border-box;
}

.innerContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 160px;
  max-width: var(--max-width);
}

@media only screen and (max-width: 600px) {
  .innerContainer {
    padding-top: 100px;
    gap: unset;
  }
  .bottomContainer{
    padding: unset;
  }
}