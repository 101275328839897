.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.bottomContainer{
  display: flex;
  justify-content: space-between;
}

.leftContainer{
  width: 640px;
}

.formContainer{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.rightContainer{
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 320px;
}

.contactDetail{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contactDetail p:last-child{
  color: var(--text-secondary);
}

.emailSentContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--success-05);
  color: var(--success);
  border: 1px solid var(--success);
  border-radius: 5px;
  padding: 14px 16px;
}

.emailSentContainer svg{
  margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  .container{
    padding: 0 var(--screen-margin);
    box-sizing: border-box;
  }
  .bottomContainer{
    flex-direction: column;
  }
  .leftContainer,
  .rightContainer{
    width: 100%;
  }
  .leftContainer button{
    width: 100%;
  }
  .formContainer,
  .rightContainer{
    margin-bottom: 100px;
  }
}