.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000CC;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}
  
.closeButton {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
}

.video{
    width: var(--max-width);
}

@media only screen and (max-width: 600px) {
    .overlay{
        width: 100%;
        height: 100%;
    }
    .video{
        width: 90%;
    }
  }