  .landingContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: #000000B2;
    position: relative;
    overflow: hidden;
  }
  
  .videoBackground {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    transform: translate(-50%, -50%);
    width: 100%;
    object-fit: cover;
  }

  .logoWrapper{
    display: flex;
    position: absolute;
    top: 72px;
    left: 0;
    gap: 24px;
  }

  .logoWrapper p{
    color: var(--text-secondary);
    margin-top: -3px;
  }
  
  .logo{
    width: 170px;
    height: 72px;
  }

  .poweredBy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }

  .poweredBy svg{
    height: auto;
    width: 85px;
    margin-left: 2px;
    color: var(--text-secondary);
  }
  
  .landingContent{
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .middleContainer,
  .textContainer,
  .buttonsContainer{
    display: flex;
    flex-direction: column;
  }
  
  .middleContainer{
    width: 100%;
    gap: 32px;
    padding: 0 var(--screen-margin);
    box-sizing: border-box;
  }
  
  .textContainer{
    gap: 16px;
  }
  
  .buttonsContainer{
    flex-direction: row;
    gap: 16px;
  }
  
  .textContainer img{
    width: fit-content;
    height: 48px;
  }
  
  .textContainer p{
    max-width: 520px;
    color: var(--text-secondary);
  }

  @media only screen and (max-width: 600px) {
    .landingContent {
      flex-direction: column;
       justify-content: center;
    }
    .middleContainer{
      align-items: center;
    }
    .middleContainer img{
      width: 100%;
    }
    .middleContainer p{
      text-align: center;
    }
    .logoWrapper{
      top: 32px;
      left: unset;
    }
    .logo{
      width: 64px;
      height: 64px;
    }
    .buttonsContainer{
      flex-direction: column;
      width: 100%;
    }
    .buttonsContainer button{
      width: 100%;
    }
    .poweredBy svg{
      margin-left: 1px;
    }
 }