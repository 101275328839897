/* Base button styles */
.buttonBase {
    font-family: 'Avenir Next', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    padding: 10px 16px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    text-transform: uppercase;
    color: var(--text-inverted);
    border: 2px solid transparent; /* Ensure base border for consistent size */
    width: fit-content;
  }
  
  /* Primary button styles */
  .primaryButton {
    background-color: var(--accent-primary);
  }
  
  .primaryButton:hover {
    background-color: var(--bg-white);
  }
  
  /* Outline button styles */
  .outlineButton {
    background-color: transparent;
    color: var(--accent-primary);
    border: 2px solid var(--accent-primary);
  }
  
  .outlineButton:hover {
    background-color: var(--bg-white);
    color: var(--text-inverted);
    border: 2px solid transparent; /* Keep border width, just transparent */
  }

  .disabled{
    cursor: not-allowed;
    opacity: 38%;
  }
  